<template>

  <div class="search">
       
    <input 
      type="text"
      @keyup="search" 
      v-model="keyword" 
      :placeholder="placeholder"> 

    <i class="icon-close" v-if="keyword.length" @click="remove"></i>
    <i class="icon-search" @click="search"></i>

  </div>
  
</template>

<script setup>
 
import debounce from 'lodash/debounce';

const props = defineProps({
  placeholder: {
    type: String,
    default: ''
  }, 
  val: {
    type: String,
    default: ''
  }, 
})
const emits = defineEmits(['search']);

const keyword = ref(props.val);

 

const remove = () => {
  keyword.value = '';
  emits('search', keyword.value);
}


const search = debounce((event) => { 
  emits('search', keyword.value);
}, 300);



</script>

<style scoped>

.search{
  margin-right: 5px;
  position: relative;
}
.search input{
  border-radius: 30px;
  padding: 0.4rem 3.2rem 0.4rem 0.6rem;
}
.search .icon-close,
.search .icon-search{    
  position: absolute;
  top: 0;
  display: flex;
  width: 26px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: gray;
}
.search .icon-close{
  right: 27px;
}
.search .icon-search{
  right: 4px;
}
</style>
